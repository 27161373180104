<template>
  <div class="blog">
    <!--page title start-->
    <div class="title-section text-start text-sm-center">
      <h1>My <span>blogs</span></h1>
      <span class="title-bg">posts</span>
    </div>
    <!--page title end  -->

    <!--blog content start-->
    <div class="blog-content">
      <div class="container">
        <div class="row pb-50">

          <div class="col-12 col-md-6 col-lg-6 col-xl-4 mb-30">
            <article class="post-container">
              <div class="post-thumb">
                <div class="d-block position-relative overflow-hiddenn">
                  <img class="thumb-img img-fluid" src="/images/blog/blog-post-1.webp" alt="blog-post-1">
                </div>
              </div>
              <div class="post-content">
                <h3 class="post-content-header">
                  How to Own Your Audience by Creating an Email List
                </h3>
                <p class="post-content-text open-sans-font">
                  Tomfoolery crikey bits and bobs brilliant bamboozled down the pub amongst brolly hanky panky, cack b
                </p>
              </div>
            </article>
          </div>

          <div class="col-12 col-md-6 col-lg-6 col-xl-4 mb-30">
            <article class="post-container">
              <div class="post-thumb">
                <div class="d-block position-relative overflow-hiddenn">
                  <img class="thumb-img img-fluid" src="/images/blog/blog-post-2.webp" alt="blog-post-2">
                </div>
              </div>
              <div class="post-content">
                <h3 class="post-content-header">
                  Top 10 Toolkits for Deep Learning in 2022
                </h3>
                <p class="post-content-text open-sans-font">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias beatae eius odio officiis rem vitae?
                </p>
              </div>
            </article>
          </div>

          <div class="col-12 col-md-6 col-lg-6 col-xl-4 mb-30">
            <article class="post-container">
              <div class="post-thumb">
                <div class="d-block position-relative overflow-hiddenn">
                  <img class="thumb-img img-fluid" src="/images/blog/blog-post-3.webp" alt="blog-post-3">
                </div>
              </div>
              <div class="post-content">
                <h3 class="post-content-header">
                  Everything You Need to Know About Web Accessibility
                </h3>
                <p class="post-content-text open-sans-font">
                  Dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore ma
                </p>
              </div>
            </article>
          </div>

          <div class="col-12 col-md-6 col-lg-6 col-xl-4 mb-30">
            <article class="post-container">
              <div class="post-thumb">
                <div class="d-block position-relative overflow-hiddenn">
                  <img class="thumb-img img-fluid" src="/images/blog/blog-post-4.webp" alt="blog-post-4">
                </div>
              </div>
              <div class="post-content">
                <h3 class="post-content-header">
                  How to Inject Humor & Comedy Into Your Brand
                </h3>
                <p class="post-content-text open-sans-font">
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id es
                </p>
              </div>
            </article>
          </div>

          <div class="col-12 col-md-6 col-lg-6 col-xl-4 mb-30">
            <article class="post-container">
              <div class="post-thumb">
                <div class="d-block position-relative overflow-hiddenn">
                  <img class="thumb-img img-fluid" src="/images/blog/blog-post-5.webp" alt="blog-post-5">
                </div>
              </div>
              <div class="post-content">
                <h3 class="post-content-header">
                  Women in Web Design: How To Achieve Success
                </h3>
                <p class="post-content-text open-sans-font">
                  lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                </p>
              </div>
            </article>
          </div>

          <div class="col-12 col-md-6 col-lg-6 col-xl-4 mb-30">
            <article class="post-container">
              <div class="post-thumb">
                <div class="d-block position-relative overflow-hiddenn">
                  <img class="thumb-img img-fluid" src="/images/blog/blog-post-1.webp" alt="blog-post-1">
                </div>
              </div>
              <div class="post-content">
                <h3 class="post-content-header">
                  Evergreen versus topical content: An overview
                </h3>
                <p class="post-content-text open-sans-font">
                  Still ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                </p>
              </div>
            </article>
          </div>

        </div>
      </div>
    </div>
    <!--blog content end  -->
  </div>
</template>

<script>
export default {
  name: 'BlogPage'

}
</script>