<template>
  <div class="about">
    <!--page title start-->
    <div class="title-section text-start text-sm-center">
      <h1>About <span>Me</span></h1>
      <span class="title-bg">Resume</span>
    </div>
    <!--page title end  -->

    <div class="about-main-content pb-4">
      <div class="container">
        <div class="row">
          <div class="col-xl-6 col-lg-5 col-12">
            <div class="row">
              <div class="col-12">
                <h3 class="text-uppercase fw-semibold mb-0 pb-4">Personal infos</h3>
              </div>
              <div class="col-12 d-sm-none d-block">
                <div class="about-avatar">
                  <img src="/images/homepage/profile.jpg" alt="avatar" class="img-fluid">
                </div>
              </div>
              <div class="col-12">
                <ul class="about-info-list d-flex flex-wrap list-unstyled open-sans-font">
                  <li>
                    <span class="title">First Name: </span>
                    <span class="value d-block d-sm-inline-block d-lg-block d-xl-inline-block fw-semibold">Rahat</span>
                  </li>

                  <li>
                    <span class="title">Last Name: </span>
                    <span
                        class="value d-block d-sm-inline-block d-lg-block d-xl-inline-block fw-semibold">Chowdhury</span>
                  </li>

                  <li>
                    <span class="title">Age: </span>
                    <span class="value d-block d-sm-inline-block d-lg-block d-xl-inline-block fw-semibold">22</span>
                  </li>

                  <li>
                    <span class="title">Nationality: </span>
                    <span
                        class="value d-block d-sm-inline-block d-lg-block d-xl-inline-block fw-semibold">Bangladeshi</span>
                  </li>

                  <li>
                    <span class="title">Freelance: </span>
                    <span
                        class="value d-block d-sm-inline-block d-lg-block d-xl-inline-block fw-semibold text-green">Available</span>
                  </li>

                  <li>
                    <span class="title">Address: </span>
                    <span
                        class="value d-block d-sm-inline-block d-lg-block d-xl-inline-block fw-semibold">Bangladesh</span>
                  </li>

                  <li>
                    <span class="title">Phone: </span>
                    <span class="value d-block d-sm-inline-block d-lg-block d-xl-inline-block fw-semibold">+88 01708-74654</span>
                  </li>

                  <li>
                    <span class="title">Email: </span>
                    <span class="value d-block d-sm-inline-block d-lg-block d-xl-inline-block fw-semibold">crahat4@gmail.com</span>
                  </li>

                  <li>
                    <span class="title">Skype: </span>
                    <span class="value d-block d-sm-inline-block d-lg-block d-xl-inline-block fw-semibold">Rahat Chowdhury</span>
                  </li>

                  <li>
                    <span class="title">Languages: </span>
                    <span class="value d-block d-sm-inline-block d-lg-block d-xl-inline-block fw-semibold">Bangla, Hindi, English</span>
                  </li>
                </ul>
                <a href="javascript:void(0)" class="btn-theme">
                  <span class="btn-text">Download Cv</span>
                  <span class="btn-icon">
                    <i class="fa fa-download"></i>
                  </span>

                </a>
              </div>
            </div>
          </div>
          <div class="col-xl-6 col-lg-7 col-12 mt-5 mt-lg-0">
            <div class="row">
              <div class="col-6">
                <div class="box-status">
                  <h3 class="status-num position-relative">12</h3>
                  <p class="status-title m-0 open-sans-font position-relative text-uppercase">Years of
                    <span class="d-block">Experience</span></p>
                </div>
              </div>
              <div class="col-6">
                <div class="box-status">
                  <h3 class="status-num position-relative">100</h3>
                  <p class="status-title m-0 open-sans-font position-relative text-uppercase">Completed
                    <span class="d-block">projects</span></p>
                </div>
              </div>
              <div class="col-6">
                <div class="box-status">
                  <h3 class="status-num position-relative">100</h3>
                  <p class="status-title m-0 open-sans-font position-relative text-uppercase">Happy
                    <span class="d-block">Customers</span></p>
                </div>
              </div>
              <div class="col-6">
                <div class="box-status">
                  <h3 class="status-num position-relative">53</h3>
                  <p class="status-title m-0 open-sans-font position-relative text-uppercase">Awards
                    <span class="d-block">Won</span></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr class="separator">
        <!--skills start-->
        <div class="row pb-3">
          <div class="col-12">
            <div class="text-uppercase pb-4 pb-sm-5 mb-3 mb-sm-0 text-start text-sm-center custom-title ft-wt-600">My
              skills
            </div>
          </div>


          <div class="col-lg-6">
            <div class="skills">
              <div class="each mb-4">
                <p>HTML</p>
                <div class="progress mt-2">
                  <div role="progressbar" aria-valuenow="98" aria-valuemin="0" aria-valuemax="100" class="progress-bar"
                       style="width: 98%;"></div>
                </div>
              </div>
              <div class="each mb-4">
                <p>Javascript</p>
                <div class="progress mt-2">
                  <div role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" class="progress-bar"
                       style="width: 75%;"></div>
                </div>
              </div>
              <div class="each mb-4">
                <p>Css</p>
                <div class="progress mt-2">
                  <div role="progressbar" aria-valuenow="98" aria-valuemin="0" aria-valuemax="100" class="progress-bar"
                       style="width: 98%;"></div>
                </div>
              </div>
              <div class="each mb-4">
                <p>PHP</p>
                <div class="progress mt-2">
                  <div role="progressbar" aria-valuenow="95" aria-valuemin="0" aria-valuemax="100" class="progress-bar"
                       style="width: 95%;"></div>
                </div>
              </div>
              <div class="each mb-4">
                <p>Wordpress</p>
                <div class="progress mt-2">
                  <div role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" class="progress-bar"
                       style="width: 50%;"></div>
                </div>
              </div>

              <div class="each mb-4">
                <p>Jquery</p>
                <div class="progress mt-2">
                  <div role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" class="progress-bar"
                       style="width: 75%;"></div>
                </div>
              </div>
              <div class="each mb-4">
                <p>Angular</p>
                <div class="progress mt-2">
                  <div role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" class="progress-bar"
                       style="width: 70%;"></div>
                </div>
              </div>
              <div class="each mb-4">
                <p>React</p>
                <div class="progress mt-2">
                  <div role="progressbar" aria-valuenow="98" aria-valuemin="0" aria-valuemax="100" class="progress-bar"
                       style="width: 50%;"></div>
                </div>
              </div>

              <div class="each mb-4">
                <p>Python</p>
                <div class="progress mt-2">
                  <div role="progressbar" aria-valuenow="45" aria-valuemin="0" aria-valuemax="100" class="progress-bar"
                       style="width: 45%;"></div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6 skill-img-wrap">

              <img src="/images/about/skills.svg" alt="skills" class="img-fluid w-50">
          </div>


        </div>
        <!--skills end  -->
        <hr class="separator mt-1">

        <!--Resume section start-->
        <div class="row">
          <div class="col-12">
            <div class="text-uppercase pb-4 pb-sm-5 mb-3 mb-sm-0 text-start text-sm-center custom-title ft-wt-600">My
              Experience & Education
            </div>
          </div>

          <div class="col-lg-6">
            <div class="resume-box">
              <ul class="resume-list list-unstyled">

                <li class="resume-list-item">
                  <div class="icon">
                    <i class="fa fa-briefcase"></i>
                  </div>
                  <span class="time text-uppercase open-sans-font">2013 - 2018</span>
                  <h5 class="title text-uppercase">UI/UX designer <span class="place open-sans-font">Themeforest</span>
                  </h5>
                  <p class="open-sans-font desc">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quasi
                    reprehenderit, ut. Ad earum eius exercitationem nemo nostrum officia similique voluptas!</p>
                </li>

                <li class="resume-list-item">
                  <div class="icon">
                    <i class="fa fa-briefcase"></i>
                  </div>
                  <span class="time text-uppercase open-sans-font">2023 - Present</span>
                  <h5 class="title text-uppercase">Consaltant <span class="place open-sans-font">Redishketch</span>
                  </h5>
                  <p class="open-sans-font desc">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quasi
                    reprehenderit, ut. Ad earum eius exercitationem nemo nostrum officia similique voluptas!</p>
                </li>

                <li class="resume-list-item">
                  <div class="icon">
                    <i class="fa fa-briefcase"></i>
                  </div>
                  <span class="time text-uppercase open-sans-font">2023 - Present</span>
                  <h5 class="title text-uppercase">Web developer <span class="place open-sans-font">Redishketch</span>
                  </h5>
                  <p class="open-sans-font desc">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quasi
                    reprehenderit, ut. Ad earum eius exercitationem nemo nostrum officia similique voluptas!</p>
                </li>

              </ul>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="resume-box">
              <ul class="resume-list list-unstyled">

                <li class="resume-list-item">
                  <div class="icon">
                    <i class="fa fa-briefcase"></i>
                  </div>
                  <span class="time text-uppercase open-sans-font">2015</span>
                  <h5 class="title text-uppercase">Enginnering digree <span class="place open-sans-font">Oxford university</span>
                  </h5>
                  <p class="open-sans-font desc">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quasi
                    reprehenderit, ut. Ad earum eius exercitationem nemo nostrum officia similique voluptas!</p>
                </li>

                <li class="resume-list-item">
                  <div class="icon">
                    <i class="fa fa-briefcase"></i>
                  </div>
                  <span class="time text-uppercase open-sans-font">2012</span>
                  <h5 class="title text-uppercase">Master Digree <span
                      class="place open-sans-font">kiev university</span>
                  </h5>
                  <p class="open-sans-font desc">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quasi
                    reprehenderit, ut. Ad earum eius exercitationem nemo nostrum officia similique voluptas!</p>
                </li>

                <li class="resume-list-item">
                  <div class="icon">
                    <i class="fa fa-briefcase"></i>
                  </div>
                  <span class="time text-uppercase open-sans-font">2009</span>
                  <h5 class="title text-uppercase">Bachelor digree <span
                      class="place open-sans-font">Kiev high school</span>
                  </h5>
                  <p class="open-sans-font desc">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quasi
                    reprehenderit, ut. Ad earum eius exercitationem nemo nostrum officia similique voluptas!</p>
                </li>

              </ul>
            </div>
          </div>
        </div>
        <!--Resume section end  -->

      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: 'AboutPage',
  mounted() {


  }

}
</script>